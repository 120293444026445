<template>
  <div class="__delivery_report">
    <small style="font-size: 10px; color: red"
      >{{ $t("dashboard.campaign_history.button_report.sub_text") }}
    </small>
    <div class="row justify-content-center">
      <div class="col-md-12 flex__container">
        <div class="_top_search" v-if="logintype == 'sms'">
          <div class="search">
            <div class="__content">
              <p>{{ $t("dashboard.campaign_history.button_report.search") }}</p>
              <div class="form_input_container">
                <el-input
                  type="text"
                  style="width: 100%"
                  :placeholder="
                    $t('dashboard.campaign_history.button_report.search_sender')
                  "
                  autocomplete="false"
                  v-model="selectedSenderID"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="date">
          <div class="__content">
            <p>{{ $t("dashboard.campaign_history.button_report.from") }}</p>
            <div class="form_input_container">
              <el-date-picker
                style="width: 100%"
                v-model="from"
                type="date"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yy"
                @change="updateEndDateRange"
                popper-class="notranslate">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="date">
          <div class="__content">
            <p>{{ $t("dashboard.campaign_history.button_report.to") }}</p>
            <div class="form_input_container">
              <el-date-picker
                style="width: 100%"
                v-model="to"
                type="date"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yy"
                :picker-options="pickerOptions"
                :picker-value="pickerValue"
                popper-class="notranslate">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="actions">
          <button
            class="view"
            @click="viewReport"
            :disabled="from == null || to == null">
            {{ $t("dashboard.campaign_history.button_report.view") }}
          </button>
          <el-button
            type="primary"
            v-loading="exportLoading"
            class="export"
            :disabled="from == null || to == null"
            @click="exportDeliveryReport">
            {{ $t("dashboard.campaign_history.button_report.export") }}
          </el-button>
        </div>
      </div>
      <div class="col-md-12 mb-5">
        <div class="row" v-loading="loading" v-if="loading">
          <div class="col-md-12" style="height: 200px"></div>
        </div>
        <div class="row justify-content-center" v-if="!loading">
          <div class="___view" v-if="campaignLabels.length">
            <RecentSMSChart
              :campaignLabels="campaignLabels"
              :CampaignCount="campaignSeries" />
          </div>
          <div class="mt-5" v-else>
            <p>{{ $t("dashboard.campaign_history.button_report.no_data") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RecentSMSChart from "./reporting_sms";
  import store from "@/state/store.js";
  import moment from "moment";
  import logintType from "@/helpers/loginType";
  import downloadcsv from "../../../helpers/downloadcsv";
  export default {
    name: "delivery-report",
    components: {
      RecentSMSChart,
    },
    data() {
      return {
        search: "",
        selectedSenderID: "",
        from: null,
        to: null,
        senderIDs: [],
        timeout: null,
        loading: false,
        options: [],
        list: [],
        error: "",
        loading: false,
        campaignLabels: [],
        campaignSeries: [],
        pickerOptions: {
          disabledDate: this.disabledEndDate,
        },
        exportLoading: false,
      };
    },
    computed: {
      logintype() {
        return logintType();
      },
      pickerValue() {
        return this.from ? new Date(this.from) : null;
      },
    },
    methods: {
      updateEndDateRange() {
        this.to = null;
      },
      disabledEndDate(time) {
        if (!this.from) return false;
        const thirtyDaysAfterStartDate = new Date(this.from);
        thirtyDaysAfterStartDate.setDate(
          thirtyDaysAfterStartDate.getDate() + 30
        );
        return (
          time.getTime() > thirtyDaysAfterStartDate.getTime() ||
          time.getTime() < this.from.getTime()
        );
      },
      formatDate(date) {
        if (!date) {
          return "";
        }
        let newDate = moment(date).format();
        return newDate.split("T")[0];
      },
      resporting() {
        this.loading = true;
        this.campaignSeries = [];
        this.campaignLabels = [];
        store
          .dispatch("reporting/deliveryReport", {
            to: this.formatDate(this.to),
            from: this.formatDate(this.from),
          })
          .then((response) => {
            if (response.length) {
              response.forEach((data) => {
                this.campaignSeries.push(data.Counts);
                if (data.status == "FAILED") {
                  data.status = "ACCEPTED";
                }
                this.campaignLabels.push(data.status);
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors.push(this.$t("misc.general_error"));
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          })
          .finally(() => (this.loading = false));
      },
      viewReport() {
        if (this.selectedSenderID) {
          this.loading = true;
          this.campaignSeries = [];
          this.campaignLabels = [];
          store
            .dispatch("reporting/smsDeliveryReportBySenderID", {
              sender_id: this.selectedSenderID,
              to: this.to,
              from: this.from,
            })
            .then((response) => {
              this.loading = false;
              if (response.length) {
                response.forEach((data) => {
                  this.campaignSeries.push(data.Counts);

                  this.campaignLabels.push(data.status);
                });
              }
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.data == undefined) {
                this.errors.push(this.$t("misc.general_error"));
              }
              if (err.response && err.response.status === 404) {
                this.errors = Object.values(err.response.data.data);
              }
            })
            .finally(() => (this.loading = false));
        } else if (this.to && this.from) {
          if (logintType() == "sms") {
            this.resporting();
          } else {
            this.reportingVoice();
          }
        }
      },
      remoteMethod(query) {
        if (query !== "") {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.options = this.list.filter((item) => {
              return (
                item.sender_id.toLowerCase().indexOf(query.toLowerCase()) > -1
              );
            });
          }, 1000);
        } else {
          this.options = [];
        }
      },
      handleSelect(item) {},
      reportingVoice() {
        this.loading = true;
        this.campaignSeries = [];
        this.campaignLabels = [];
        store
          .dispatch("reporting/deliveryReportVoice", {
            to: this.formatDate(this.to),
            from: this.formatDate(this.from),
          })
          .then((response) => {
            if (response.length) {
              response.forEach((data) => {
                this.campaignSeries.push(data.Counts);
                let status;
                if (data.status == 0) {
                  status = "PENDING";
                } else if (data.status == 1) {
                  status = "ANSWERED";
                } else if (data.status == 2) {
                  status = "BUSY";
                } else if (data.status == 3) {
                  status = "NO ANSWER";
                }
                this.campaignLabels.push(status);
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors.push(this.$t("misc.general_error"));
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          })
          .finally(() => (this.loading = false));
      },
      exportDeliveryReport() {
        this.exportLoading = true;
        if (this.to && this.from) {
          if (logintType() == "sms") {
            store
              .dispatch("reporting/exportSMSDeliveryReport", {
                to: this.formatDate(this.to),
                from: this.formatDate(this.from),
              })
              .then((data) => {
                this.exportLoading = false;
                if (data.message) {
                  this.$alert(data.message, "Success", {
                    type: "success",
                    closeOnClickModal: true,
                  });
                  return;
                }
                downloadcsv(
                  data,
                  `delivery_report_${this.formatDate(
                    this.from
                  )}_to_${this.formatDate(this.to)}_sms.csv`
                );
                if (data) {
                  this.$notify({
                    message: this.$t(
                      "dashboard.campaign_history.report_exported"
                    ),
                    type: "success",
                  });
                }
              })
              .catch((err) => {
                this.exportLoading = false;
                this.$notify({
                  message: this.$t("misc.general_error"),
                  type: "error",
                });
              })
              .finally(() => (this.exportLoading = false));
          } else {
            store
              .dispatch("reporting/exportVoiceDeliveryReport", {
                to: this.formatDate(this.to),
                from: this.formatDate(this.from),
              })
              .then((data) => {
                this.exportLoading = false;
                downloadcsv(
                  data,
                  `delivery_report_${this.formatDate(
                    this.from
                  )}_to_${this.formatDate(this.to)}_voice.csv`
                );
                if (data) {
                  this.$notify({
                    message: this.$t(
                      "dashboard.campaign_history.report_exported"
                    ),
                    type: "success",
                  });
                }
              })
              .catch((err) => {
                this.exportLoading = false;
                this.$notify({
                  message: this.$t("misc.general_error"),
                  type: "error",
                });
              })
              .finally(() => (this.exportLoading = false));
          }
        }
      },
    },
    mounted() {
      this.list = store.getters["senderid/senderIDs"];
      // Depending on login type run get data for sms or voice
      if (logintType() == "sms") {
        this.resporting();
        // Analytics
        this.$mixpanel.track("SMS Delivery Report Page");
        if (this.$store.getters["auth/currentUser"].country === "CI") {
          this.$mixpanelFranco.track("SMS Delivery Report Page");
        }
      } else {
        this.reportingVoice();
        // Analytics
        this.$mixpanel.track("Voice Delivery Report Page");
        if (this.$store.getters["auth/currentUser"].country === "CI") {
          this.$mixpanelFranco.track("Voice Delivery Report Page");
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .flex__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }

  .___view {
    width: 700px;
    margin-top: 5em;
  }

  .searched_item {
    border: none;
    background-color: #fff;
    box-shadow: 2px 2px 4px rbga(0, 0, 0, 0.5);

    ul {
      margin: 0px;
      padding: 0px;
    }

    ul li {
      list-style: none;
      font-style: normal;
      font-weight: normal;
      width: 100%;
      font-size: 14px;
      line-height: 15px;
      color: #484a4f;
      border-bottom: 1px solid #484a4f;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }

    ul li button {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }

  .__content {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      color: #484a4f;
      margin-bottom: 0px;
      padding-left: 1em;
    }
  }

  .__btn_content {
    padding-top: 1em;
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 159.3%;
      text-align: center;
      height: 38px;
      border-radius: 5px;

      &:first-child {
        border: 1px solid #979797;
        box-sizing: border-box;

        color: #979797;
        background-color: #fff;
        width: 80px;
      }

      &:last-child {
        background: #f7921c;
        border-radius: 5px;
        color: #fff;
        border: 1px solid #f7921c;
        width: 92px;
        margin-left: 2em;
      }
    }
  }

  ._top_search {
    max-width: 292.69px;
    width: 100%;
    display: flex;
    margin-right: 2em;
  }

  .search {
    max-width: 292.69px;
    width: 100%;
  }

  .date {
    width: 203.57px;
    margin-right: 2em;
  }

  .actions {
    padding-top: 1em;
    display: flex;
    column-gap: 20px;
    align-items: center;

    .view {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 159.3%;
      text-align: center;
      color: #fffdfd;
      border: 1px solid #979797;
      box-sizing: border-box;
      border-radius: 5px;
      width: 80px;
      height: 48px;
      outline: none;
      background-color: #f7921c;
      border-color: #f7921c;
    }
  }

  @media screen and (max-width: 850px) {
    ._top_search {
      margin-right: 1em;
    }

    .date {
      margin-right: 1em;
    }
  }

  @media screen and (max-width: 600px) {
    .flex__container {
      flex-wrap: wrap;
      margin-top: 0px;
    }

    ._top_search,
    .date,
    .actions {
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
    }

    .search {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1em;
    }

    .date {
      margin-bottom: 1em;
    }

    .actions {
      justify-content: flex-end;
    }
  }

  .view:disabled,
  .export:disabled {
    cursor: not-allowed;
    background-color: #dfdede;
    color: #979797;
    border-color: transparent;
  }
</style>
